"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { useAuth } from "@context/User";
import { cn } from "@libs/utils";

import { Button } from "@components/ui/button";

import { assemble } from "@configs/features/assemble";
import { signinSelectors } from "@configs/features/test-selectors";

import Navbar from "../Navbar";
import UserMenu from "../UserMenu";

import { HeaderSkeleton } from "./Skeleton";

const Header = ({ className }: { className?: string }) => {
  const user = useAuth();
  const pathname = usePathname();

  const navbarContent = () => {
    if (user.isLoading) {
      return <HeaderSkeleton />;
    }

    if (!user.id) {
      return (
        <Button asChild data-testid={signinSelectors.loginBtn}>
          <a href="/api/auth/login?returnTo=/standard-work/shifts">Login</a>
        </Button>
      );
    }

    return (
      <>
        <Navbar />
        <div className="flex items-center gap-3">
          {pathname === "/" && (
            <Button asChild size="sm">
              <Link href="/dashboard">Access Assemble</Link>
            </Button>
          )}
          <UserMenu />
        </div>
      </>
    );
  };

  return (
    <>
      <header className={cn("py-5", className)}>
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <Link href={user.id ? "/dashboard" : "/"}>
              <Image src={assemble.logo} alt="Logo Come Assemble" width={135} height={26} />
            </Link>

            {navbarContent()}
          </div>
        </div>
      </header>

      {user?.id && user.profilePercentage < 100 && (
        <div className="bg-warning/20 p-3 text-center text-[#FF9500]">
          Profile {Math.round(user.profilePercentage)}% complete.{" "}
          {pathname !== "/profile" && (
            <Link href="/profile" className="font-semibold underline underline-offset-4">
              Complete profile
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
